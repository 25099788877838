import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"

const CookiesPolicy = () => (
  <Layout>
    <SEO title="Polityka cookies" />

    <GlobalTop title="Polityka cookies" />

    <section className="page-default">
      <div className="container">
        <p>
          Niniejsza Polityka dotycząca Ciasteczek odnosi się do strony
          konfederacka11.pl Przez używanie serwisu konfederacka11.pl wyrażasz
          zgodę na używanie ciasteczek zgodnie z tą Polityką Ciasteczek. Jeżeli
          nie zgadzasz się na używanie przez nas ciasteczek, powinieneś zmienić
          ustawienia swojej przeglądarki w odpowiedni sposób lub zrezygnować z
          używania strony konfederacka11.pl.
        </p>
        <h4>Co to są ciasteczka?</h4>
        <p>
          Ciasteczka (ang. cookies) to niewielkie pliki, zapisywane i
          przechowywane na twoim komputerze, tablecie lub smartphonie podczas
          gdy odwiedzasz różne strony w internecie. Ciasteczko zazwyczaj zawiera
          nazwę strony internetowej, z której pochodzi, „długość życia”
          ciasteczka (to znaczy czas jego istnienia), oraz przypadkowo
          wygenerowany unikalny numer służący do identyfikacji przeglądarki, z
          jakiej następuje połączenie ze stroną internetową.
        </p>
        <h4>Do czego używamy ciasteczek?</h4>
        <p>
          konfederacka11.pl używa ciasteczek w różnych celach: by serwis działał
          szybciej i był łatwiejszy w użyciu, do zbierania anonimowych,
          zagregowanych statystyk, które pozwalają nam zrozumieć jak ludzie
          używają naszych stron i pomagają w poprawianiu ich funkcjonalności i
          zawartości. Używając ciasteczek w wyżej opisany sposób nigdy nie
          identyfikujemy tożsamości użytkowników na podstawie informacji
          przechowywanych w ciasteczkach.
        </p>
        <h4>Jak długo przechowywane są dane w ciasteczkach?</h4>
        <p>
          Na stronie konfederacka11.pl mogą być używane dwa rodzaje ciasteczek –
          sesyjne oraz stałe. Te pierwsze pozostają na Twoim urządzeniu jedynie
          podczas korzystania ze strony konfederacka11.pl. Ciasteczka stałe
          pozostają na Twoim urządzeniu tak długo jak długo mają ustawiony czas
          życia lub do momentu kiedy je usuniesz.
        </p>
        <h4>Rodzaje ciasteczek używanych na stronie konfederacka11.pl</h4>
        <p>
          Konieczne do działania stron – Niezbędne do prawidłowego
          funkcjonowania strony konfederacka11.pl, pozwalają Ci na poruszanie
          się po nich oraz używanie ich elementów. Przykładowo mogą zapamiętywać
          poprzednie czynności (np. otwarte teksty) podczas wracania na stronę w
          tej samej sesji.
        </p>
        <p>
          Poprawiające wydajność – Zbieranie informacji o tym jak odwiedzający
          korzystają ze strony konfederacka11.pl poprzez dostarczanie informacji
          na temat obszarów które odwiedzają, czasu jaki na nich spędzają oraz
          problemów jakie na nich napotykają, jak np. komunikaty o błędach. To
          pozwala nam poprawiać działanie strony konfederacka11.pl.
        </p>
        <h4>Czy używamy ciasteczek podmiotów trzecich?</h4>
        <p>
          Tak, korzystając z serwisu konfederacka11.pl możesz otrzymywać
          ciasteczka pochodzące od współpracujących z konfederacka11.pl
          podmiotów trzecich takich jak np. Facebook czy Google. Więcej
          informacji na temat tych ciasteczek możesz znaleźć na stronach
          internetowych poszczególnych podmiotów trzecich.
        </p>
        <h4>
          W jaki sposób mogę zmienić ustawienia dot. ciasteczek albo je usunąć?
        </h4>
        <p>
          Większość przeglądarek internetowych jest początkowo ustawionych na
          automatyczne przyjmowanie ciasteczek. Możesz jednak zmienić ustawienia
          przeglądarki tak, aby ciasteczka były blokowane – w całości lub w
          jakiejś części, np. tylko od stron trzecich, albo aby każdorazowo
          otrzymywać komunikat w momencie kiedy ciasteczka są wysyłane na Twoje
          urządzenie. Pamiętaj jednak, że jeżeli zablokujesz używane przez nas
          ciasteczka, może to negatywnie wpłynąć na wygodę korzystania ze strony
          konfederacka11.pl, na przykład możesz nie być w stanie odwiedzić
          pewnych obszarów strony konfederacka11.pl bądź nie otrzymywać
          spersonalizowanych informacji podczas ich przeglądania. Uniemożliwisz
          nam także zbieranie anonimowych informacji nt. używania naszych stron
          w celu stałego poprawiania zawartości strony konfederacka11.pl.
        </p>
      </div>
    </section>
  </Layout>
)

export default CookiesPolicy
